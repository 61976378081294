
    
<template>
  <div class="dataset-page">
    <!-- content -->
    <div class="dataset-page_type">
      <!-- 排行榜维度板块 -->
      <div class="type_content">
        <el-tabs
          tab-position="left"
          class="left-tab"
          v-model="modelTabsActvieName"
          @tab-click="handleDimClick"
        >
          <el-tab-pane
            v-for="leftItem in dimensionList"
            :key="leftItem.EnglishName"
            :label="leftItem.ChineseName"
            :name="leftItem.ChineseName"
          >
            <div class="content-box">
              <div class="header">
                <div class="title">{{ leaderBoardName }}</div>
                <div class="option d-flex">
                  <!-- 日期选择 -->
                  <!-- <el-select v-model="value" size="mini" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->

                  <!-- 中英文切换 -->
                  <!-- <el-radio-group
                    v-model="switchRadio"
                    text-color="#000"
                    fill="#FFF"
                    size="mini"
                    style="margin-left: 32px"
                  >
                    <el-radio-button label="总榜"></el-radio-button>
                    <el-radio-button label="中文"></el-radio-button>
                    <el-radio-button label="英文"></el-radio-button>
                  </el-radio-group> -->
                </div>
              </div>

              <!-- 榜单列表 -->
              <div class="table-box">
                <el-table :data="tableData" class="table-list">
                  <!-- 序号 -->
                  <!-- <el-table-column
                    type="index"
                    width="200px"
                    :index="indexMethod"
                  > 
                  </el-table-column>-->
                  <el-table-column
                    v-for="(item, i) in BoardList"
                    :key="i"
                    :prop="item.name"
                    :label="item.label"
                    :sortable="true"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
  
  <script>
import DimensionList from "@/assets/data/dimension/dimension.json";
import LeaderboardList from "@/assets/data/leaderboard/leaderboard.json";

export default {
  data() {
    return {
      tableData: [],
      BoardList: [
        { name: "rank", label: "排名" },
        { name: "model", label: "模型名" },
        { name: "company", label: "公司名" },
        { name: "open_source", label: "开源/闭源", sortable: "true" },
        { name: "score", label: "分数", sortable: "true" },
        { name: "remark", label: "备注",sortable: "false" },
      ],
      options: [
        {
          value: "1",
          label: "2024年7月榜单",
        },
        {
          value: "2",
          label: "2024年6月榜单",
        },
        {
          value: "3",
          label: "2024年5月榜单",
        },
        {
          value: "4",
          label: "2024年4月榜单",
        },
        {
          value: "5",
          label: "2024年3月榜单",
        },
      ],
      value: "1",
      switchRadio: "总榜", //暂时只支持总榜

      dimensionList: DimensionList.FIN, //维度列表
      leaderboardList: LeaderboardList.FIN, //排行榜列表

      dimensionIndex: 0,
      modelTabsActvieName: "",

      leaderBoardName: "金融模型榜单", //排行榜模块名称
    };
  },
  watch: {
    // 监听整个 $route 对象
    $route: function () {
      // to 和 from 分别代表新旧路由
    },
  },
  created() {
    this.init();
    this.getTableData()
  },
  mounted() {},
  methods: {
    init() {
      // 默认选择第一个维度
      this.modelTabsActvieName =
        this.dimensionList[this.dimensionIndex].ChineseName;
    },
     // 获取tableData方法
     getTableData() {
      const name = this.dimensionList[this.dimensionIndex].EnglishName;
      // 匹配当前选择的维度中的EnglishName为数据集的维度dimension名称
      const target = this.leaderboardList.find(
        (item) => item.dimension === name
      );
      const socreList = target.score_lists;

      this.currentSocreLists = socreList;

      // 筛选当前 lang 总榜/中文/英文
      this.tableData = socreList.find(
        (item) => item.lang === this.switchRadio
      ).list;

    },
    handleDimClick(tab) {
      const index = tab.index;
      this.dimensionIndex = index;
      this.getTableData()
      // const name = this.dimensionList[index].EnglishName;
      // // 匹配当前选择的维度中的EnglishName为数据集的维度dimension名称
      // this.datasetList = this.originDatasetList.filter(
      //   (item) => item.dimension === name
      // );
    },
    // indexMethod(index) {
    //   return index + 1;
    // },
  },
};
</script>
  <style lang="scss" scoped >
::v-deep .el-tabs__item.is-disabled {
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
}
.el-table th.el-table__cell {
  font-size: 0.8rem;
}
::v-deep .el-tabs--left .el-tabs__header.is-left {
  width: 200px;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  font-size: 0.8rem;
}
::v-deep.el-table--enable-row-transition .el-table__body td.el-table__cell {
  font-size: 0.75rem;
  padding: 0;
  height: 3rem;
  div {
    height: 1.6rem;
    padding-top: 1%;
    line-height: 1;
  }
}

.dataset-page {
  .dataset-page_banner {
    background: url("~@/assets/images/leaderboard/header_bg.png");
    height: 326px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    position: relative;
    .line {
      height: 2px;
      width: 100%;
      background-color: #94bfff;
      position: absolute;
      left: 0;
      top: 176px;
    }
    .timeline-list {
      width: 100%;
      padding-top: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      .timeline-item {
        width: 150px;
      }
      .timeline-item:not(:last-child) {
        margin-right: 40px;
      }
      .item-date {
        font-weight: bold;
        font-size: 16px;
        color: #1d2129;
        margin-bottom: 9px;
      }
      .item-img {
        width: 24px;
        height: 80px;
        margin-bottom: 9px;
      }
      .item-desc {
        font-weight: 400;
        font-size: 14px;
        color: #86909c;
      }
    }
  }

  .dataset-page_type {
    width: 100%;
    margin-bottom: 32px;

    .type_content {
      .content-box {
        .header {
          border-radius: 4px;
          background: #0e42d2;
          border: 1px solid #e5e6eb;
          text-align: left;
          padding: 19px 16px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            height: 28px;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #fff;
          }
          .option {
            ::v-deep .el-radio-button--mini .el-radio-button__inner {
              color: #ffffff !important;
              background-color: #0e42d2 !important;
              border-color: #fff !important;
              box-shadow: -1px 0 0 0 #fff !important;
            }

            ::v-deep
              .el-radio-button__orig-radio:checked
              + .el-radio-button__inner {
              color: #000 !important;
              background-color: #fff !important;
              border-color: #fff !important;
              box-shadow: -1px 0 0 0 #fff !important;
            }
          }
        }
        .code,
        .robot,
        .finance,
        .med,
        .hardware {
          height: 106px;
          background-size: cover;
          background-position: center;
          background-color: #fff;
        }

        .table-box {
          display: flex;
          .board-box {
            width: 574px;
            display: flex;

            .level {
              display: flex;
              flex-direction: column;
            }

            .item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 82px;
              width: 310.5px;
              margin-bottom: 24px;
              box-shadow: 3px 3px 0 rgba(201, 240, 255, 0.45);
              .left {
                font-weight: 500;
                font-size: 56px;
                color: #bedaff;
                line-height: 64px;
              }
              .right {
                text-align: right;
                font-weight: 400;
                font-size: 12px;
                color: #1d2129;
              }
            }
          }
          .board-img {
            width: 240px;
            height: 456px;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
</style>